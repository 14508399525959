<template lang="pug">
div.layout
  GoUpArrow
  MainNavbar
  div
    slot
</template>

<script setup>
import { useSettingsStore } from '~/stores/settings'

const settingsStore = useSettingsStore()
await settingsStore.getLangTree()
</script>


<style lang='scss' scoped>
.layout {
  --bcg-color: var(--light-color);
  --text-color: var(--dark-color);
  --bcg-menu-color: var(--light-color);
}
</style>
